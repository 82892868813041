<template>
  <section>
    <Card class="dashboard-portfolio" dis-hover>
      <Row class="mb-8">
        <Col :lg="11" :span="24">
          <Row class="mb-8" justify="space-between" type="flex">
            <Col :sm="10" :span="24" class="d-flex align-center">
              <!--              <img :src="require('@/assets/images/logo-min.png')" alt="" height="32" width="32"/>-->
              <img :src="require('@/assets/images/LogoNew.svg')" alt="" height="32" width="32"/>
              <span class="ms-2">
                {{ $t('dashboard.marketWallet') }}
              </span>
              <Tooltip class="ms-2" max-width="292" placement="bottom-end" theme="light">
                <div slot="content" class="font-Medium-12 break-word">
                  {{ $t('dashboard.marketTip') }}
                </div>
                <Icon size="20" type="ios-information-circle-outline"/>
              </Tooltip>
            </Col>
            <Col class="overflow-hidden">
              <span class="font-Medium-18">
                {{ marketAssets.strTotalIrrBalance || "0" | numberWithCommas }}
              </span>
              IRR
            </Col>
          </Row>

          <div
              v-if="marketListLoading"
              class="loader-wrapper"
          >
            <Loader/>
          </div>

          <div
              v-else-if="!marketAssets.memberWalletTop5DtoList?.length"
              class="nodata-wrapper"
          >
            <BaseIcon name="NoData"/>
            <div class="font-Regular-12 text-grey mt-2" v-html="$t('dashboard.noMarketWallets')">
            </div>
          </div>

          <template v-else>
            <Row
                v-for="item in marketAssets?.memberWalletTop5DtoList"
                :key="`market-${item.unit}`"
                class="asset-card mb-2"
            >
              <Col :sm="8" :span="24" class="d-flex align-center justify-start flex-query margin-top">
                <img v-if="item.logoUrl" :src="item.logoUrl" height="20" width="20" @error="setDefaultLogo"/>
                <img v-else :src="require('../../../../src/assets/images/coin_place_holder.png')" height="20" width="20"/>
                <span class="font-Regular-12 ms-2">
                  {{ item.displayName }}
                </span>
              </Col>
              <Col :sm="8" :span="24" class="flex-query text-start margin-top order-1">
                <div class="font-Regular-12" dir="ltr">
                  {{ item.strBalance | numberWithCommas }}
                  {{ item.unit }}
                </div>
              </Col>
              <Col :sm="8" :span="24" class="d-flex flex-query justify-end text-start margin-top order-2">
                <router-link
                    :class="{ 'disabled-link': !item.canTransfer || !getWithdrawStatus }"
                    :disabled="!item.canTransfer || !getWithdrawStatus"
                    :to="`/transfer?name=${item.unit}`"
                    class="d-block font-Regular-12 active-link"
                >
                  {{ $t('dashboard.transferToExcoino') }}
                </router-link>
              </Col>
            </Row>
          </template>
        </Col>

        <Col :lg="2" :span="24">
          <div class="horizontal-divider"></div>
          <Divider class="vertical-divider" type="vertical"/>
        </Col>

        <Col :lg="11" :span="24">
          <Row class="mb-8" justify="space-between" type="flex">
            <Col :sm="10" :span="24" class="d-flex align-center">
              <img :src="require('@/assets/images/excoino_logo.svg')" alt="" height="32" width="32"/>
              <span class="ms-2">
              {{ $t('dashboard.excoinoWallet') }}
            </span>
              <Tooltip class="ms-2" max-width="292" placement="bottom-end" theme="light">
                <div slot="content" class="font-Medium-12 break-word">
                  {{ $t('dashboard.excoinoTip') }}
                </div>
                <Icon size="20" type="ios-information-circle-outline"/>
              </Tooltip>
            </Col>
            <Col>
              <a class="wallet-link" @click="excoinoWalletModal = true">
                {{ $t('dashboard.viewWallet') }}
              </a>
              <!--              <a :href="baseUrl + '/panel/wallet'" class="wallet-link">-->
              <!--                {{ $t('dashboard.viewWallet') }}-->
              <!--              </a>-->
            </Col>
          </Row>

          <div
              v-if="excoinoListLoading"
              class="loader-wrapper"
          >
            <Loader/>
          </div>

          <div
              v-else-if="!excoinoAssets?.length"
              class="nodata-wrapper"
          >
            <BaseIcon name="NoData"/>
            <div class="font-Regular-12 text-grey mt-2">
              {{ $t('dashboard.noData') }}
            </div>
          </div>

          <template v-else>
            <Row
                v-for="item in excoinoAssets"
                :key="`excoino-${item.displayName}`"
                class="asset-card mb-2"
            >
              <Col :sm="8" :span="24" class="d-flex align-center justify-start flex-query margin-top">
                <img v-if="item.icon" :src="item.icon" height="20" width="20" @error="setDefaultLogo"/>
                <img v-else :src="require('../../../../src/assets/images/coin_place_holder.png')" height="20" width="20"/>
                <span class="font-Regular-12 ms-2">
                {{ item.displayName }}
              </span>
              </Col>
              <Col :sm="8" :span="24" class="flex-query text-start margin-top order-1">
                <div class="font-Regular-12" dir="ltr">
                  {{ item.strBalance | numberWithCommas }}
                  {{ item.unit }}
                </div>
              </Col>
              <Col :sm="8" :span="24" class="d-flex flex-query justify-end text-start margin-top order-2">
                <!--                :href="baseUrl + `/panel/operations/transfer?from=${item.displayName}`"-->
                <a
                    @click="openTransferModal(item.unit)"
                    class="d-block font-Regular-12 primary-color"
                >
                  {{ $t('dashboard.transferToMarket') }}
                </a>
              </Col>
            </Row>
          </template>
        </Col>
      </Row>

      <div class="tip-card d-flex mb-6">
        <Icon class="pt-2 me-3" color="#3889D0" size="18" type="ios-information-circle-outline"/>
        <p class="tip-desc text-start">
          {{ $t('dashboard.walletsTip') }}
        </p>
      </div>

      <Button class="text-black wallets-btn" to="/assets" type="warning">
        <div class="d-flex align-center justify-center">
          <BaseIcon class="me-1" name="Wallet"/>
          {{ $t('dashboard.allMarketAssets') }}
        </div>
      </Button>
    </Card>

    <Modal
        v-model="marketTransferModal"
        class-name="custom-modal rtl mt-15-prcnt"
        width="500"
    >
      <div slot="header" class="custom-modal-header font-Medium-14 text-center">
        {{ $t('dashboard.transferToMarket') }}
      </div>

      <div class="font-Regular-14 custom-modal-body text-center mb-4">
        <p>{{ $t('dashboard.marketTransferDesc') }}</p>
      </div>

      <div slot="footer" class="px-8 py-8 w-100">
        <Button long class="font-Medium-14 text-black wallet-modal-btn" type="warning" @click="goToExcoinoTransfer">
          {{ $t('dashboard.transferFromExcoino', {unit: transferUnit}) }}
        </Button>
      </div>
    </Modal>

    <Modal
        v-model="excoinoWalletModal"
        class-name="custom-modal mt-15-prcnt"
        :dir="isLocaleLTR ? 'ltr' : 'rtl'"
        width="500"
    >
      <div slot="header" class="custom-modal-header font-Medium-14 text-center">
        {{ $t('dashboard.viewExcoinoWallet') }}
      </div>
      <div class="font-Regular-14 custom-modal-body text-start" :style="isLocaleLTR ?'padding-inline:15px':''">
        <p>{{ $t('dashboard.excoinoWalletDesc') }}</p>
      </div>

      <div slot="footer" class="px-8 py-8 w-100">
        <Button long class="font-Medium-14 text-black wallet-modal-btn" type="warning" @click="goToExcoinoWallet">
          {{ $t('dashboard.goToExcoinoWallet') }}
        </Button>
      </div>
    </Modal>
  </section>
</template>

<script>
import Loader from "@/components/UI/LoadingComp"
import BaseIcon from "@/components/UI/BaseIcon";
import {mapGetters} from "vuex";

export default {
  name: "index",
  components: {
    Loader,
    BaseIcon
  },
  props: {
    marketAssets: {
      type: Object,
      default() {
        return {}
      }
    },
    excoinoAssets: {
      type: Array,
      default() {
        return []
      }
    },
    marketListLoading: {
      type: Boolean,
      required: true
    },
    excoinoListLoading: {
      type: Boolean,
      required: true
    },
  },
  computed: {
    ...mapGetters({
      getWithdrawStatus: "global/GET_WITHDRAW_STATUS",
    }),
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_EXCOINO_PANEL_URL,
      marketTransferModal: false,
      excoinoWalletModal: false,
      transferUnit: ""
    }
  },
  methods: {
    openTransferModal(unit) {
      this.transferUnit = unit;
      this.marketTransferModal = true;
    },
    setDefaultLogo(e) {
      e.target.src = require("../../../../src/assets/images/coin_place_holder.png");
    },
    goToExcoinoWallet() {
      window.location = `${this.baseUrl}/panel/wallet`
    },
    goToExcoinoTransfer() {
      window.location = `${this.baseUrl}/panel/operations/transfer?from=${this.transferUnit}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import "Assets";
</style>